import { React, useState } from 'react'
import "./landingpage.css"
import left from "../../media/left.png"
import right from "../../media/right.png"
import selfie from "../../media/selfie.png"
import tick from "../../media/tick.svg"
import circle from "../../media/circle.svg"
import logo from "../../media/logo.svg"
import insta from "../../media/ins.svg"
import fb from "../../media/fac.svg"
import ln from "../../media/lin.svg"
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ThankYoupopup from '../ThankYou'
import { Link } from 'react-scroll';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('*Name is required'),
    email: Yup.string().email('*Invalid email').required('*Email is required'),
    mobile: Yup.string().matches(/^\d{10}$/, '*Mobile must be 10 digits').required('*Mobile is required'),
    creatorType: Yup.string(),
    socialMediaLink: Yup.string().required('*Link is required'),
    description: Yup.string(),
});


const Landingpage = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state variable


    const initialValues = {
        name: '',
        email: '',
        mobile: '',
        creatorType: '',
        socialMediaLink: '',
        description: '',
    };
    console.log("form data is", initialValues)

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
      
          const response = await axios.post('https://api.creator.ind.in/api/creators-ind/', values);
          console.log('Form submitted successfully:', response.data);
          setIsSubmitted(true);
      
          // Reset the form
          resetForm();
      
          setSubmitting(false);
        } catch (error) {
          console.error('Error submitting form:', error);
          setSubmitting(false);
        }
      };
      
      

    return (
        <div className='landingpage'>
            <div className="top">
                <div className="details">
                    <div className="join">
                        <spam className="black">Join Us, To Get Access to</spam>
                        <spam className="gold">yourname@creator.ind.in</spam>


                    </div>
                    <div className="para">
                        Being a creator is about bringing your unique ideas and visions to life, whether through art, writing, music, or any form of expression. It's the act of turning imagination into reality and sharing it with the world. And we are here to help you build to bring the professional aspect to your creativity.
                    </div>
                    <Link to={'bottom'} smooth={true} duration={500} className="access">
                        Get Access
                    </Link>

                </div>
                <div className="images">
                    <img className='left' src={left} alt="" />
                    <img className='right' src={right} alt="" />

                </div>

            </div>
            <div className="middle">
                <div className="benifit">
                    <div className="beni">
                        Benefits on your way.
                    </div>
                    <div className="tick">

                        <div> <img src={tick} alt="" /> Brand Collaborations</div>
                        <div> <img src={tick} alt="" /> Dedicated Talent Manager</div>
                        <div> <img src={tick} alt="" /> Guidance for better reach</div>
                        <div style={{gap:"5px"}}> <img src={tick} alt="" style={{marginRight:"6px"}} />Content Email on  <span> Creator.ind.in </span> domain</div>


                    </div>

                </div>
                <img className='middle-img' src={circle} alt="" />

            </div>
            <div className="bottom" id='bottom'>
                <div className="form-container">
                    <h2>Join the Movement.</h2>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="form">
                                <div className="name-email">
                                    <div className='error-feild'>

                                        <div className="name">
                                            Name <div className="line"></div>
                                            <Field type="text" name="name" />
                                        </div>
                                        <ErrorMessage name="name" component="div" className="error" />
                                    </div>
                                    <div className='error-feild'>


                                        <div className="email">
                                            Email <div className="line"></div>
                                            <Field type="email" name="email" />
                                        </div>
                                        <ErrorMessage name="email" component="div" className="error" />
                                    </div>
                                </div>
                                <div className="number-type">

                                        <div className='error-feild1'>
                                            
                                    <div className="number">
                                        +91 <div className="line"></div>
                                        <Field
                                            type="number"
                                            name="mobile"
                                            placeholder='Continue with Mobile Number'
                                            maxLength='10'
                                            />
                                            </div>
                                        <ErrorMessage name="mobile" component="div" className="error" />
                                    </div>
                                    
                                    <div className="type">
                                        Creator Type <div className="line"></div>
                                        <Field
                                            type="text"
                                            name="creatorType"
                                            placeholder='Fashion Influencer'
                                        />
                                        <ErrorMessage name="creatorType" component="div" className="error" />
                                    </div>
                                </div>
                                <div className="media">
                                    Social Media Link <div className="line"></div>
                                    <Field
                                        type="text"
                                        name="socialMediaLink"
                                        placeholder='Social Link'
                                    />
                                    <ErrorMessage name="socialMediaLink" component="div" className="error" />
                                </div>
                                <div className="desc">
                                    <Field
                                        as="textarea"
                                        name="description"
                                        placeholder='Say Something About Yourself ....'
                                    />
                                    <ErrorMessage name="description" component="div" className="error" />
                                </div>
                                {isSubmitted && <ThankYoupopup onClose={() => setIsSubmitted(false)} />}

                                <button type="submit" className="submit" onClick={handleSubmit}>
                                    {isSubmitting ? "Submitting..." : "Submit"}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
                <img className='bottom-img' src={selfie} alt="" />
                <a href='https://brich.in/' className="logo">
                    <img src={logo} alt="" />
                    <div className="brich">
                        <h3>B RICH</h3>
                        <p>Art of Social  Engineering .</p>
                    </div>

                </a>

            </div>
            <div className="footer">
                <div className="social">
                    <a href="https://instagram.com/brich.in?igshid=MzRlODBiNWFlZA=="><img src={insta} alt="" /></a>
                    <a href="https://www.linkedin.com/company/b-rich/"><img src={ln} alt="" /></a>
                    <a href="https://www.facebook.com/profile.php?id=61551967450079&mibextid=LQQJ4d"><img src={fb} alt="" /></a>

                </div>

            </div>

        </div>
    )
}

export default Landingpage
