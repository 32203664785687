import './App.css';
import Landingpage from './Components/LandingPage/Landingpage';

function App() {
  return (
    <div className="App">
      <Landingpage/>
      
    </div>
  );
}

export default App;
